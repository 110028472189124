<template>
  <div class="tab-pane active">
    <div class="card-body">
      <div class="row">
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="card">
            <div class="card-body">
              <p class="card-text">Eventos Auditados</p>
              <span class="card-text">95</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <Chart ref="statusChart" title="Eventos" />
        </div>
        <div class="col-6">
          <Chart ref="statusChart" title="Eventos por Conta" />
        </div>
        <div class="col-6">
          <Chart ref="statusChart" title="Eventos por Usuário" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/rebranding/Chart.vue';

export default {
  name: 'AdminAnalytics',
  components: {
    Chart,
  },
  data() {
    return {};
  },
  created() {
    this.fetch();
  },
  fetch() {
  },
};
</script>
